<template>
  <v-row class="service-main-wrapper mt_dec--30">
    <!-- Start Single Service  -->
    <v-col lg="3" md="3" sm="6" cols="12" v-for="(service, i) in serviceContent" :key="i">
      <div class="service service__style--2 text-left bg-gray" data-aos="fade-up">
        <div class="service">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
import feather from 'feather-icons';
export default {
  props: {
    season: 0,
  },
  data() {
    return {
      season1: [
        {
          icon: 'cast',
          title: 'Teaser Site Open',
          desc: ` K-BEAUTY Metaverse Community and BEAUTYPLAY World will be opened. `,
        },
        {
          icon: 'layers',
          title: 'ZEPETO Map Open',
          desc: ` The ZEPETO map of BEAUTYPLAY World will be revealed. `,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-Up Store Event',
          desc: ` The pop-up store event for the BEAUTYPLAY World Collaboration brand will begin. `,
        },
        {
          icon: 'thumbs-up',
          title: 'Launching',
          desc: ` Congratulations on the successful launch of Metaverse and Geppetto Maps. `,
        },
      ],
      season2: [
        {
          icon: 'cast',
          title: 'Site Renewal',
          desc: ` Check new information on the K-Beauty metaverse community.`,
        },
        {
          icon: 'layers',
          title: 'Zepeto Map Renewal',
          desc: ` Beauty Play Zepeto World is getting renovated. `,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-up Store',
          desc: ` Meet collaborating brands in the metaverse world. `,
        },
        {
          icon: 'thumbs-up',
          title: 'Renewal',
          desc: ` Congratulation on the successful renewal of Beauty Play Zepeto World.`,
        },
      ],
      season3: [
        {
          icon: 'users',
          title: 'White List ',
          desc: ` 뷰티플레이 월드 NFT의 회원과 제휴 파트너사를 모집합니다.`,
        },
        {
          icon: 'award',
          title: 'White Paper',
          desc: ` NFT 홀더에 대한 UTILITY(멤버십과 혜택)이 정해집니다.`,
        },
        {
          icon: 'send',
          title: 'Minting',
          desc: ` 회원들에게 뷰티플레이 월드 NFT 에어드랍을 진행합니다.`,
        },
        {
          icon: 'maximize',
          title: 'Expanding',
          desc: ` 뷰티플레이 월드 세계관이 확장되고 신규 캐릭터가 추가됩니다.`,
        },
      ],
    };
  },
  computed: {
    serviceContent() {
      return this.season === 2 ? this.season2 : this.season1;
    },
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
