<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="koz-story-content">
        <v-row>
          <v-col md="10" sm="12" cols="12" class="story-content-inner">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in storyContent" :key="item.id">
                <img :src="item.src" alt="story image" />
                <v-card flat v-for="(item, i) in item.content" :key="i">
                  <v-card-text>
                    <div class="author-info">
                      <h6>
                        <span>{{ item.name }}</span> - {{ item.designation }}
                      </h6>
                    </div>
                    <div class="inner">
                      <p v-html="item.description">
                        <!--{{ item.description }}-->
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
                <!-- End .v-tabs -->
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col md="2" sm="12" cols="12" class="">
            <div class="story-tabs">
              <v-tabs v-model="tab" centered hide-slider center-active>
                <v-tab v-for="item in tabItems" :key="item.id" :ripple="false">
                  <div class="thumb">
                    <img :src="item.src" alt="story image" />
                  </div>
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
        </v-row>
        <!-- End .v-tabs-items -->
      </div>
      <!-- End .koz-story-content -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      storyContent: [
        {
          id: 1,
          content: [
            {
              name: '#1 ',
              description: `Dear My Diary <br/><br/>
Today I got an unknown package. When I unboxed it, there was a small handheld mirror. Then with curiosity, I brought it up in front of my face. At that moment, I was swirled into the mirror. It was a bit dizzy trip, but I was able to arrive in ‘Beauty Play World’ in the virtual world. <br><br>Oh, I also met a friend, JK, and even went to the ‘Beauty Play Academy’. I eventually enrolled in the academy because I was told that I was able to learn the secrets of K-Beauty. However, I am a bit hesitant. I never thought that I was beautiful so I am not confident if I can do it well. <br><br> Can I do it well? Please cheer for me to find my unique beauty and be able to go through this journey. Also, be ready for my new avatar in Beauty Play World. `,
              designation: 'Journey to ‘Beauty Play World’ in the Mirror – Is This ‘Beauty Play World’?',
            },
          ],
          src: require('../../../assets/images/story/story2-1.png'),
        },
        {
          id: 2,
          content: [
            {
              name: '#2 ',
              description: `Dear My Diary<br/><br/>
Finally, today is the day that I graduate from ‘Beauty Play Academy’. During my time here, I was able to learn the secrets of K-Beauty such as personal color, skincare, and makeup. In the past, I thought I wasn’t beautiful but I don’t think like that anymore. In ‘Beauty Play Academy’ I learned that anyone can discover their own unique ‘K-Beauty’. <br><br>By this, I was able to have a new dream. It is to become a ‘K-Beauty Influencer’ and help anyone who believes that they are not beautiful, just like me in the past. I would like to encourage and tell them that anyone can find their own beauty.<br><br>I, YUJIN, will become a ‘K-Beauty Influencer’ who makes everyone beautiful! `,
              designation: ' Graduating ‘Beauty Play Academy’ and the New Dream – I am Going to be a Beauty Influencer!  ',
            },
          ],
          src: require('../../../assets/images/story/story2-2.png'),
        },
        {
          id: 3,
          content: [
            {
              name: '#3 ',
              description: `Dear My Diary<br/><br/>
                        It has been 6 months since I graduated from ‘Beauty Play Academy’. During that time, I had a wonderful time meeting different friends around the world and grow as a K-Beauty Influencer. If you are curious about projects that I was involved in or Beauty Play content, then visit the accounts below~!!<br><br><br>
                        Instagram : @Beautyplay.global<br>
                        &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        @Beautyplay.official<br><br>
                        YouTube : <br>
                        <a href="www.youtube.com/@beautyplayofficial">www.youtube.com/@beautyplayofficial</a><br>
                        YUJIN : <br>
                        <a href="https://web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en">https://web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en</a> <br>
                        `,
              designation: " Beauty Influencer ‘YUJIN’ – Spreading 'K-Beauty Spirit‘ Around the World.",
            },
          ],
          src: require('../../../assets/images/story/story2-3.png'),
        },
        {
          id: 4,
          content: [
            {
              name: '#4 ',
              description: `Dear My Diary<br/><br/>
              With my experience in K-Beauty, I would like to create magical cosmetics. What about lipstick that transforms you or a mask sheet that you can wear like a mask, wouldn’t it be cool? Yes, I have decided to establish my own K-Beauty cosmetic company! <br><br> The name of my company will be ‘Beauple Cosmetic’. It will be in ‘Beauty Play World’ so you should come and visit. Inside the ‘Beauple Cosmetic’, there will be introductions to projects in which I was involved as an influencer and my favorite cosmetics. Please visit and leave a review!`,
              designation: ' YUJIN’s ‘Beauple Cosmetic’ – Let’s Create Magical Cosmetics!',
            },
          ],
          src: require('../../../assets/images/story/story2-4.png'),
        },
        {
          id: 5,
          content: [
            {
              name: '#5 ',
              description: `Dear My Diary<br/><br/>I have a great news. Now in Beauty Play World, there is a ‘Mystery Cosmetic Shop’. Here, all the magical cosmetics from around the world are placed on display. In fact, I have handpicked all the cosmetics sent from magicians around the world after using each one of them. <br><br>Also, through the ‘K-Beauty Maker Contest’ we will be introducing more fun and fascinating cosmetics. Great awards and prizes are rewarded, and we will be creating more fascinating cosmetics so don’t forget about the ‘K-Beauty Maker Contest’!`,
              designation: ' Come and Visit Mystery Cosmetic Shop',
            },
          ],
          src: require('../../../assets/images/story/story2-5.png'),
        },
        /*
                {
                    id: 6,
                    content: [{
                        name: "#6 ",
                        description: `\“This is 'BEAUTYPLAY World', everything is ready to find your own real beauty!\”<br />JK replied confidently.<br />\“Can I be with you so that you can find yourself shining here?\”<br />Yujin nodded at JK's suggestion.<br />\“Will I be able to be a shining person?\”<br />Yujin, who still pours out questions as if she can't believe it. JK said with a big smile.<br />\“Then I'll guide you as I know you accepted it. Come on, let's go!\”`,
                        designation: " True Beauty Project begins!",
                    }],
                    src: require("../../../assets/images/story/story-6.jpg")
                }
                */
      ],

      tabItems: [
        {
          id: 1,
          src: require('../../../assets/images/story/story2-thum-1.png'),
        },
        {
          id: 2,
          src: require('../../../assets/images/story/story2-thum-2.png'),
        },
        {
          id: 3,
          src: require('../../../assets/images/story/story2-thum-3.png'),
        },
        {
          id: 4,
          src: require('../../../assets/images/story/story2-thum-4.png'),
        },
        {
          id: 5,
          src: require('../../../assets/images/story/story2-thum-5.png'),
        },
        /*
                {
                    id: 6,
                    src: require("../../../assets/images/story/story-thum-6.jpg"),
                }
                */
      ],
    };
  },
};
</script>
