<template>
  <v-row class="service-main-wrapper mt_dec--30">
    <!-- Start Single Service  -->
    <v-col lg="3" md="3" sm="6" cols="12" v-for="(service, i) in serviceContent" :key="i">
      <div class="service service__style--2 text-left bg-gray" data-aos="fade-up">
        <div class="service">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
import feather from 'feather-icons';
export default {
  props: {
    season: 0,
  },
  data() {
    return {
      season1: [
        {
          icon: 'cast',
          title: 'Teaser Site Open',
          desc: ` K-BEAUTYメタバースコミュニティ、ビューティープレイワールドがオープンされます`,
        },
        {
          icon: 'layers',
          title: 'Zepeto Map Open',
          desc: ` ビューティープレイワールドのゼペットマップが公開されます。`,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-Up Event',
          desc: ` ビューティープレイワールドコラボブランドのポップアップストアイベントが始まります。 `,
        },
        {
          icon: 'thumbs-up',
          title: 'Launching',
          desc: ` メタバースとゼペットマップのサービスを開始いたします。ありがとうございました。`,
        },
      ],
      season2: [
        {
          icon: 'cast',
          title: 'Site Renewal',
          desc: ` K-ビューティーメタバースコミュニティで
                    新しい情報をチェックしてください `,
        },
        {
          icon: 'layers',
          title: 'Zepeto Map Renewal',
          desc: ` ビューティープレイワールドのゼペットワールドマップが
                    リニュアルされます。 `,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-Up Store',
          desc: ` コラボブランドの
                    メタバース空間を
                    ワールドで体験してみてください `,
        },
        {
          icon: 'thumbs-up',
          title: 'Renewal',
          desc: ` ビューティープレイワールドの
                    ゼペットワールドマップのリニュアル完成、おめでとうございます。`,
        },
      ],
      season3: [
        {
          icon: 'users',
          title: 'White List ',
          desc: ` 뷰티플레이 월드 NFT의 회원과 제휴 파트너사를 모집합니다.`,
        },
        {
          icon: 'award',
          title: 'White Paper',
          desc: ` NFT 홀더에 대한 UTILITY(멤버십과 혜택)이 정해집니다.`,
        },
        {
          icon: 'send',
          title: 'Minting',
          desc: ` 회원들에게 뷰티플레이 월드 NFT 에어드랍을 진행합니다.`,
        },
        {
          icon: 'maximize',
          title: 'Expanding',
          desc: ` 뷰티플레이 월드 세계관이 확장되고 신규 캐릭터가 추가됩니다.`,
        },
      ],
    };
  },
  computed: {
    serviceContent() {
      return this.season === 2 ? this.season2 : this.season1;
    },
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
