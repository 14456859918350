<template>
  <v-row class="service-main-wrapper mt_dec--30" style="word-break: keep-all">
    <!-- Start Single Service  -->
    <v-col lg="3" md="3" sm="6" cols="12" v-for="(service, i) in serviceContent" :key="i">
      <div class="service service__style--2 text-left bg-gray" data-aos="fade-up">
        <div class="service">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
import feather from 'feather-icons';
export default {
  props: {
    season: 0,
  },
  data() {
    return {
      season1: [
        {
          icon: 'cast',
          title: 'Teaser Site Open',
          desc: ` K-BEAUTY 메타버스 커뮤니티, 뷰플 월드가 오픈됩니다.`,
        },
        {
          icon: 'layers',
          title: 'Zepeto Map Open',
          desc: ` 뷰플 월드의 제페토 월드맵이 공개 됩니다.`,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-Up Store Event',
          desc: ` 콜라보 브랜드의 메타버스 팝업 이벤트가 시작됩니다. `,
        },
        {
          icon: 'thumbs-up',
          title: 'Launching',
          desc: ` 뷰플 월드 제페토 월드맵의 성공적인 런칭을 축하합니다.`,
        },
      ],
      season2: [
        {
          icon: 'cast',
          title: 'Site Renewal',
          desc: ` K-BEAUTY 메타버스 커뮤니티에서  새로운 정보를 확인하세요. `,
        },
        {
          icon: 'layers',
          title: 'Zepeto Map Renewal',
          desc: ` 뷰플 월드의 제페토 월드맵이 새단장 됩니다. `,
        },
        {
          icon: 'shopping-bag',
          title: 'Pop-Up Store',
          desc: ` 콜라보 브랜드의 메타버스 공간을  월드에서 만나보세요. `,
        },
        {
          icon: 'thumbs-up',
          title: 'Renewal',
          desc: ` 뷰플 월드 제페토 월드맵의 성공적인 리뉴얼을 축하합니다.`,
        },
      ],
      season3: [
        {
          icon: 'users',
          title: 'White List ',
          desc: ` 뷰티플레이 월드 NFT의 회원과 제휴 파트너사를 모집합니다.`,
        },
        {
          icon: 'award',
          title: 'White Paper',
          desc: ` NFT 홀더에 대한 UTILITY(멤버십과 혜택)이 정해집니다.`,
        },
        {
          icon: 'send',
          title: 'Minting',
          desc: ` 회원들에게 뷰티플레이 월드 NFT 에어드랍을 진행합니다.`,
        },
        {
          icon: 'maximize',
          title: 'Expanding',
          desc: ` 뷰티플레이 월드 세계관이 확장되고 신규 캐릭터가 추가됩니다.`,
        },
      ],
    };
  },
  computed: {
    serviceContent() {
      return this.season === 2 ? this.season2 : this.season1;
    },
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
