<template>
  <div style="word-break: keep-all">
    <!-- Start Header Area -->
    <HeaderOnePageCustom>
      <img slot="logo" :src="logoLight" class="logo-light" />
      <img slot="logo-dark" :src="logoDark" class="logo-dark" />
    </HeaderOnePageCustom>
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <section class="parallax-slider">
      <div class="slider-content first-page" id="home">
        <Banner class="slider-inner d-flex justify-center align-center">
          <h1 slot="heading-title" data-text="WONDEROUS K-BEAUTY" class="heading-title oleo mb-0 pb-0">
            <span
              >WONDEROUS<br class="d-flex d-sm-none" />
              K-BEAUTY</span
            >
          </h1>
          <h1 slot="heading-title" data-text="METAVERSE IN ZEPETO" class="heading-title oleo">
            METAVERSE<br class="d-flex d-sm-none" />
            IN ZEPETO
          </h1>

          <p slot="description" class="description highlight">
            K-Beauty를 경험하는 새로운 방식 뷰티플레이 월드가 시작되었습니다. <br />
            시공간 제약을 넘어, 당신만의 K-Beauty 메타버스를 만나보세요!
          </p>
          <a
            slot="button"
            class="btn-default btn-large landing"
            target="_black"
            href="https://web.zepeto.me/ko/detail/atARi3wK879tSOMTv29X4nk?referrer=copylink_share">
            <span class="subject">뷰 티 플 레 이</span> <br />
            월드 놀러가기
          </a>
        </Banner>
        <div class="slider-bg-wrap">
          <div class="bird-container" v-for="n in 4" :key="n">
            <div class="bird"></div>
          </div>
          <div class="slider-bg" data-black-overlay="1"></div>
        </div>
      </div>
      <div class="slider-content second-page" id="remain">
        <Banner class="slider-inner d-flex justify-center align-center">
          <h1 slot="heading-title" class="heading-title landing" data-text="">
            뷰티플레이<br class="d-flex d-sm-none" />
            제페토 월드맵 <br />
            '뷰티플레이월드' 시즌2
          </h1>
          <p slot="description" class="description dark">
            뷰티 인플루언서 유진이와 <br />
            신기한 뷰티 홍보관-미스터리 뷰티숍
          </p>
          <a slot="button" class="btn-default btn-large landing" target="_black" href="https://web.zepeto.me/share/user/profile/zepeto.kcii?referrer">
            <span class="subject">뷰티 인플루언서</span> <br />
            유진을 소개합니다
          </a>
        </Banner>
        <div class="slider-bg-wrap">
          <span class="snow-wrap">
            <i v-for="n in 30" :key="n" class="snow"></i>
          </span>
          <div class="slider-bg" data-black-overlay="1"></div>
        </div>
      </div>
    </section>
    <!-- End Slider Area  -->

    <!-- Start Event Area -->
    <section class="ptb--120" id="event">
      <div class="inner text-center">
        <h2 class="heading-title oleo" data-aos="fade-down">EVENT</h2>
        <p class="description p-15" data-aos="fade-up">뷰티플레이 시즌2 미스터리 뷰티숍 이벤트에 참여하세요!</p>
      </div>
      <!-- Start Event One -->
      <div class="event-area ptb--80">
        <v-container>
          <EventOne />
        </v-container>
      </div>
      <!-- End Event One -->
      <!-- Start Event Two -->
      <div class="event-area pb--40">
        <v-container>
          <EventTwo />
        </v-container>
      </div>
    </section>
    <!-- End Event Two  -->
    <!-- End Event Area -->

    <!-- Start Story Area  -->
    <section class="bg_color--1" id="story">
      <div class="call-to-action-wrapper call-to-action callto-action-style-2 text-white-wrapper">
        <div class="bg_image story-bg bg_image--30 ptb--160 ptb_lg--80 ptb_md--80 ptb_sm--80" data-black-overlay="6">
          <v-container>
            <CallToActionCustom />
          </v-container>
        </div>
      </div>
      <div class="koz-story-area bg_color--1 ptb--120" id="story">
        <v-container>
          <Story />
        </v-container>
      </div>
    </section>
    <!-- Start Story Area  -->

    <!-- Start Roadmap Area  -->
    <section class="service-area ptb--120 bg_color--5" id="roadmap">
      <v-container>
        <div class="inner text-center">
          <h2 class="heading-title oleo" data-aos="fade-down">ROADMAP</h2>
        </div>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title" data-aos="fade-up">
              <span class="subtitle">SEASON 1</span>
              <!-- <h2 class="heading-title">SEASON 1</h2> -->
              <p class="description">
                시즌1에서는 뷰티플레이 메타버스 커뮤니티와 제페토 월드맵이 공개됩니다. <br />
                뷰티플레이 콜라보 브랜드 이벤트 팝업스토어를 런칭합니다.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceCustom :season="1" />
        <br />
        <br />
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title" data-aos="fade-up">
              <span class="subtitle">SEASON 2</span>
              <!-- <h2 class="heading-title">SEASON 1</h2> -->
              <p class="description">
                시즌2에서는 뷰티플레이 메타버스 커뮤니티와 제페토 월드맵이 리뉴얼 됩니다.
                <br />
                유진이가 선정한 미스터리편집숍 선정제품을 뷰티플레이 월드에서 만나보세요!
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceCustom :season="2" />
      </v-container>
      <v-container class="ptb--120 m-15">
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title" data-aos="fade-up">
              <span class="subtitle">SEASON 3</span>
              <!-- <h2 class="heading-title">Services provide for you.</h2> -->
              <p class="description">SEASON3의 플랜은 2024년에 공개됩니다.</p>
            </div>
          </v-col>
        </v-row>
        <!--<ServiceCustom :season="2" />-->
      </v-container>
    </section>
    <!-- Start Roadmap Area  -->

    <!-- Start Footer Area  -->
    <FooterCustom />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderOnePageCustom from '../components/header/HeaderOnePageCustom';
import Banner from '../components/slider/Banner';
import EventOne from '../components/event/EventOne';
import EventTwo from '../components/event/EventTwo';
import CallToActionCustom from '../components/callto-action/CallToActionCustom';
import Story from '../components/story/Story';
import ServiceCustom from '../components/service/ServiceCustom';
import FooterCustom from '../components/footer/FooterCustom';
import FlipCountdown from 'vue2-flip-countdown';
import { getAuth } from 'firebase/auth';

export default {
  components: {
    HeaderOnePageCustom,
    Banner,
    EventOne,
    EventTwo,
    CallToActionCustom,
    Story,
    ServiceCustom,
    FooterCustom,
    Banner,
    FlipCountdown,
  },
  data() {
    return {
      logoLight: require('../assets/images/logo/bpw_logo_v.png'),
      logoDark: require('../assets/images/logo/bpw_logo_v_dark.png'),
    };
  },
  mounted() {
    getAuth(this.$firebase);
  },
  beforeDestroy() {},
  methods: {
    setScreenSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .parallax-slider .slider-inner h1.heading-title {
    line-height: 52px !important;
    font-size: 38px !important;
    padding-bottom: 10px !important;
    margin-bottom: 20px !important;
    letter-spacing: -0.05rem !important;
  }
  .parallax-slider .slider-inner h1.heading-title.mb-0.pb-0 {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  .slide-btn .landing {
    font-size: 1.2rem;
  }
  .ptb--120.m-15 {
    padding: 80px 15px !important;
  }
}
</style>
