<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="koz-story-content">
        <v-row>
          <v-col md="10" sm="12" cols="12" class="story-content-inner">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in storyContent" :key="item.id">
                <img :src="item.src" alt="story image" />
                <v-card flat v-for="(item, i) in item.content" :key="i">
                  <v-card-text>
                    <div class="author-info">
                      <h6 class="story-jp-title">
                        <span>{{ item.name }}</span> - {{ item.designation }}
                      </h6>
                    </div>
                    <div class="inner">
                      <p v-html="item.description">
                        <!--{{ item.description }}-->
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
                <!-- End .v-tabs -->
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col md="2" sm="12" cols="12" class="">
            <div class="story-tabs">
              <v-tabs v-model="tab" centered hide-slider center-active>
                <v-tab v-for="item in tabItems" :key="item.id" :ripple="false">
                  <div class="thumb">
                    <img :src="item.src" alt="story image" />
                  </div>
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
        </v-row>
        <!-- End .v-tabs-items -->
      </div>
      <!-- End .koz-story-content -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      storyContent: [
        {
          id: 1,
          content: [
            {
              name: '#1 ',
              description: `ダイアリちゃんへ<br/><br/>

                        ダイアリーちゃん、こんにちは。<br/><br/>
                        今日私宛に心当たりがない荷物が送られてきました。<br/>
                        開けてみたら中には小さい手鏡があったので、<br/>
                        好奇心が沸いて鏡に顔を写したら、鏡の中に吸い込まれました！<br/>
                        少しふらふらしたけど、<br/>
                        「ビューティープレイワールド」という、とても不思議な仮想空間に着きました。<br/><br/>

                        そう、そこで素敵な友達のJKにも会えましたし、<br/>
                        「ビューティープレイアカデミー」という学校にも行けました。<br/>
                        学校ではかわいくなれるK-ビューティーの秘密が <br/>
                        分かるとのことだったので、入学を決めました。<br/>
                        しかし、今まで自分のことをかわいいと思ったことが一度もないから、<br/>
                        ちゃんとやっていけるか不安です。<br/><br/>

                        私、ちゃんとできるかな。<br/>
                        私が自分だけの美しさをちゃんと見つけて、<br/>
                        これからの旅を楽しめるように応援お願いします。<br/>
                        ビューティープレイワールドで<br/>
                        もっとかわいくなる私のアバターもお楽しみに！`,
              designation: ` 鏡の中の「ビューティープレイワールド」に旅を－ここが「ビューティープレイワールド」なの？ `,
            },
          ],
          src: require('../../../assets/images/story/story2-1.png'),
        },
        {
          id: 2,
          content: [
            {
              name: '#2 ',
              description: `ダイアリちゃんへ<br/><br/>

                        ダイアリちゃん、こんにちは。<br/><br/>

                        ついに「ビューティープレイアカデミー」を卒業します。<br/>
                        これまでパーソナルカラー、スキンケア、メイクアップまで、<br/>
                        いろんなK-ビューティーの秘密を学びました。<br/>
                        昔は自分のことをかわいいと思わなかったんだけど、今は違う。<br/>
                        「ビューティープレイアカデミー」で <br/>
                        「K-ビューティー」なら誰でも <br/>
                        自分だけの美しさが見つけられるということが分かりました。<br/><br/>

                        そこで今、新しい夢に出会えました。<br/>
                        「K-ビューティーインフルエンサー」になることです！<br/>
                        昔の私みたいに自分のことをかわいいと思えない世界の友達に <br/>
                        「みんな自分だけの本当の美しさを持ってるよ」と伝えたいです。<br/><br/>

                        これからは「K-ビューティーインフルエンサー」として、<br/>
                        みんなを美しくするユジン（YUJIN）になります。`,
              designation: '「ビューティープレイアカデミー」卒業そして新しい夢 – ビューディーインフルエンサーになる！',
            },
          ],
          src: require('../../../assets/images/story/story2-2.png'),
        },
        {
          id: 3,
          content: [
            {
              name: '#3 ',
              description: `ダイアリちゃんへ<br/><br/>

                        ダイアリちゃん、こんにちは。<br/><br/>
                        「ビューティープレイアカデミー」を卒業して６カ月が経ちました。<br/>
                        これまでK-ビューティーインフルエンサーとして <br/>
                        世界のいろんな友達に出会って、<br/>
                        一緒に成長できてとても嬉しかったです。<br/>
                        私が参加したプロジェクトや <br/>
                        ビューティープレイコンテンツが気になるなら、<br/>
                        下のアカウントに遊びに来て！<br/><br/>

                        インスタグラム： @Beautyplay.global <br/>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;@Beautyplay.official <br/><br/>
                        ユーチューブ： <a href = "https://www.youtube.com/@beautyplayofficial">www.youtube.com/@beautyplayofficial</a> <br/><br/>
                        ユジン（YUJIN）： <a href = "https://web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en">web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en</a>
`,
              designation: ' ビューディーインフルエンサー「YUJIN」 – 世界へ「K-Beauty Spirit」を届けよう',
            },
          ],
          src: require('../../../assets/images/story/story2-3.png'),
        },
        {
          id: 4,
          content: [
            {
              name: '#4 ',
              description: `ダイアリちゃんへ<br/><br/>

                            ダイアリちゃん、こんにちは。<br/><br/>

                            K-ビューティーノウハウを活かして <br/>
                            魔法のような化粧品が作りたくなりました。<br/>
                            塗るだけで変身する口紅とか、<br/>
                            仮面みたいに被るパックとか、<br/>
                            面白い化粧品が作れるんじゃないかな、と。<br/>
                            だから決めました！ <br/>
                            魔法のようなK-ビューティー化粧品会社を立ち上げようと！ <br/><br/>

                            会社の名前は「ビュープルコスメティック（Beauple Cosmetic）」です。<br/>
                            「ビューティープレイワールド」に事務所があるから、<br/>
                            ぜひ遊びに来てくださいね。<br/>
                            「ビュープルコスメティック（Beauple Cosmetic）」事務所では、<br/>
                            これまで私がインフルエンサーとして参加してきたプロジェクトや<br/>
                            大好きな化粧品をご紹介します。<br/>
                            ぜひ遊びに来て、訪問の感想も聞かせてください。<br/>
              `,
              designation: ' ユジン(YUJIN)の「ビュープルコスメティック(Beauple Cosmetic)」 - 魔法のような化粧品を作ってみよう！',
            },
          ],
          src: require('../../../assets/images/story/story2-4.png'),
        },
        {
          id: 5,
          content: [
            {
              name: '#5 ',
              description: `ダイアリちゃんへ<br/><br/>

                            ダイアリちゃん、こんにちは。<br/><br/>

                            とても良いニュースがあります。<br/>
                            ビューティープレイワールド (Beautyplayworld)に <br/>
                            「ミステリーコスメティックショップ (Mystery Cosmetic Shop)」ができました。<br/>
                            ここでは、全世界の面白い魔法化粧品を <br/>
                            全部ことができます。<br/>
                            K-ビューティーインフルエンサーの私が <br/>
                            全世界の魔法使いから送られた面白い化粧品を直接使ってみて、<br/>
                            その中からピックアップしたものを紹介しています。<br/><br/>

                            それから、「K-ビューティーメーカーコンテスト」を通じて <br/>
                            もっと面白い化粧品を紹介する計画です。<br/>
                            たくさんの賞品やプレゼントももらえて、<br/>
                            一緒に面白い化粧品が作れる <br/>
                            「K-ビューティーメーカーコンテスト」を覚えててね！
              `,
              designation: ' ミステリーコスメティックショップ(Mystery Cosmetic Shop)に遊びに来てください！',
            },
          ],
          src: require('../../../assets/images/story/story2-5.png'),
        },
        /*
                {
                    id: 6,
                    content: [{
                        name: "#6 ",
                        description: `「ここは『ビューティープレイワールド』だよ、君だけの本当の美しさを見つけることができるすべてのものが用意されている！」<br />JKが自信を持っていった。<br />「ここで輝く君を見つけることができるまで、君と一緒にいてもいいかな？」<br />JKの提案にユジンはうなずいた。<br />「本当に私も輝く人になれるのかな？」<br />相変わらず信じられないかのように、質問を吐き出すユジン。 JKがにっこり笑いながら話した。<br />「それでは受諾したと思って案内するよ。 さあ、行こう！」`,
                        designation: " True Beauty Project が始まる！",
                    }],
                    src: require("../../../assets/images/story/story-6.jpg")
                }
                */
      ],

      tabItems: [
        {
          id: 1,
          src: require('../../../assets/images/story/story2-thum-1.png'),
        },
        {
          id: 2,
          src: require('../../../assets/images/story/story2-thum-2.png'),
        },
        {
          id: 3,
          src: require('../../../assets/images/story/story2-thum-3.png'),
        },
        {
          id: 4,
          src: require('../../../assets/images/story/story2-thum-4.png'),
        },
        {
          id: 5,
          src: require('../../../assets/images/story/story2-thum-5.png'),
        },
        /* {
                    id: 6,
                    src: require("../../../assets/images/story/story-thum-6.jpg"),
                } */
      ],
    };
  },
};
</script>
