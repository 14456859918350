<template>
  <section>
    <div class="section-title text-center" data-aos="fade-down">
      <h2 class="heading-title mb-6">
        <span class="title-accent">#BEAUTYPLAYWORLD</span><br />
        K – Beauty Maker<br />Create a fun and interesting cosmetics<br />
        in Mystery Cosmetic Shop
      </h2>
    </div>
    <v-row class="row--35 align-center event-wrap">
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-right">
        <div class="section-title">
          <p class="event-desc">
            Grand Opening of Beauty Play Zepeto World Season 2!<br class="always" />
            Selecting cosmetic modeling that can be implemented in YUJIN World and the selected winners will be getting rewards of a total of 500000 KRW gift
            cards from Olive Young <br class="always" />
            <span class="red--text"></span>
          </p>
          <p class="strong event-content">
            Number of Winners: 5 <br class="always" />
            Event Period: 2023. 10. 20 ~ 2023. 11. 20<br class="always" />
            Winner Announcement: 2023.11.27 <br class="always" />
            Announcement Location: Beauty Play Club Homepage (https://beautypalay.club) <br class="always" />
            How to participate
          </p>
        </div>
        <v-sheet class="event-rules mt-1 pa-4 elevation-0" rounded>
          <div class="text-left body-1">
            1) Design a cosmetic, figure, or fashion item that goes along with the world
            <a href="https://world.zepeto.me/ko/detail/gHUHP3wK88AtdY3flcMHk9C?referrer=copylink_share" class="content-link" target="_blank"></a
            ><br class="always" />
            2) Create a fun and interesting 3D file of the items that can be uploaded to Zepeto and implemented to the world (ex: walking lipstick, eye
            enlarging eyeshadow, etc.) <br class="always" />
            3) To enter the event, send an email with an attached 3D file and an explanation of your motives for the item. Don’t forget to upload a photo taken
            in the Beauty Play World with the mandatory hashtags! <br /><br class="always" />
            <p class="body-1 mt-2 mb-4 primary--text">
              Mandatory Hashtag:<span class="strong">: #BeautyPlayWorld, #K-BeautyMaker, #MysteryCosmeticShop</span><br class="always" />
              World Tag: Beauty Play World &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Character Tag: YUJIN <span class="strong"></span>
            </p>
            <v-alert class="body-2 mb-0" dense color="grey lighten-4" border="left">
              · Any responsibility for violation of copyrights is in the submitter<br class="always" />
              · Submitted works are not returned and any copyrights regarding it will be owned by KCII (Korea Cosmetic Industry Institute).<br class="always" />
              · Works may be partially modified or changed when being used<br class="always" />
              · Works with image theft involved are excluded from the evaluation<br class="always" />
              · Evaluation criteria include Motives, Completeness, and Relevance. The final score will not be provided to submitters<br class="always" />
              · Application Email: info@kcii.re.kr
            </v-alert>
          </div>
        </v-sheet>
        <v-sheet class="gift-box my-4 pa-4 elevation-2" rounded>
          <div class="d-sm-flex text-center text-sm-left">
            <img class="w-120" src="../../../assets/images/event/gift4.png" />
            <div class="pl-4 desc-wrap">
              <h6>Prizes</h6>
              <p class="body-2 gift-desc">
                1st place Olive Young Gift Card 300000 KRW– 1 winner<br />
                2nd place Olive Young Gift Card 50000 KRW– 4 winners
                <a class="content-link" href="https://smartstore.naver.com/obt_obed/products/4481531417" target="_blank"></a>
              </p>
            </div>
          </div>
        </v-sheet>
        <!--
                <div class="about-button mt--30">
                    <a class="btn-default text-center v-btn--block" href="" target="_blank">뷰티플레이 월드 바로가기</a>
                </div>
                -->
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-left">
        <div class="thumbnail">
          <img class="w-100" src="../../../assets/images/event/event08.png" alt="Event Image" />
        </div>
      </v-col>
      <v-snackbar centered multi-line v-model="snack" :color="snackColor" elevation="10">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="rgba(255,255,255,0.6)" text v-bind="attrs" @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackColor: '',
      snackText: '',
      snack: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style>
.about-button .btn-default {
  z-index: 1000;
}
.policy {
  overflow: scroll;
}

.policy p,
.notice p {
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
  margin-bottom: 0.125rem !important;
}

.policy h5 {
  font-size: 0.9rem !important;
  margin: 8px auto !important;
}

.apply-btn-wrap {
  height: 86px;
}

.btn-apply {
  height: 56px !important;
}
.pop-header .v-toolbar__content {
  flex-wrap: inherit;
}
.pop-header.v-toolbar:not(.tab-toolbar) .v-btn.v-btn.v-size--default:not(.btn-default) {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .section-title span.subtitle {
    line-height: 1.5rem !important;
  }
}
</style>
