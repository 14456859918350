<template>
  <div>
    <v-navigation-drawer class="hidden-md-and-up header-one-page-nav-drawer" v-model="drawer" fixed width="320">
      <v-list-item class="pa-5">
        <div class="logo">
          <img src="../../../assets/images/logo/bpw_logo_v.png" alt="brand-image" />
        </div>
        <v-spacer></v-spacer>
        <v-btn class="close-icon" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)"> </v-btn>
      </v-list-item>
      <v-list>
        <scrollactive active-class="v-btn--active" bezier-easing-value=".5,0,.35,1" :offset="70">
          <v-list-item :ripple="false" v-for="item in items" :key="item.title" :to="item.to" link class="scrollactive-item">
            <v-list-item-content :class="{ 'soon-mobile': item.title === 'Shop' }">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </scrollactive>
        <v-list-item :ripple="false" class="scrollactive-item">
          <v-list-item-content class="soon-mobile">
            <v-list-item-title>Shop</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-container class="lang-btn-grp text-center">
        <v-btn-toggle v-model="lang" mandatory tile group>
          <v-btn value="KR" @click="$router.push('/')" width="86"> KR </v-btn>
          <v-btn value="EN" @click="$router.push('/en')" width="86"> EN </v-btn>
          <v-btn value="JP" @click="$router.push('/jp')" width="86"> JP </v-btn>
        </v-btn-toggle>
        <v-btn
          outlined
          rounded
          block
          class="mt-3"
          height="48"
          @click="
            drawer = !drawer;
            winnerDialog = true;
          ">
          EVENT WINNERS
        </v-btn>
      </v-container>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->
    <v-app-bar dark color="transparent" fixed elevate-on-scroll class="header-one-page-nav header-one-page-nav-two">
      <router-link to="/" class="logo">
        <img src="../../../assets/images/logo/bpw_logo_v.png" />
      </router-link>
      <!-- End brand logo -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">
        <scrollactive active-class="v-btn--active" bezier-easing-value=".5,0,.35,1" :offset="71">
          <v-btn
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            :class="{ soon: item.title === 'Shop' }"
            >{{ item.title }}</v-btn
          >
        </scrollactive>
        <nav>
          <v-btn :ripple="false" text class="soon scrollactive-item">Shop</v-btn>
        </nav>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-spacer></v-spacer>
      <div class="social-share-inner">
        <socialTwo />
      </div>
      <!-- <div class="social-share-inner ml-3">
                <ul class="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                    <li>
                        <a target="_blank" @click="logOff"><i class="mdi mdi-logout-variant signout-icon"></i></a>
                    </li>
                </ul>
    
            </div> -->
      <v-spacer></v-spacer>
      <a class="btn-default btn-border btn-opacity check" v-show="false" @click="winnerDialog = true">
        <span>EVENT WINNERS</span>
      </a>
      <v-select light rounded outlined hide-details class="lang-select hidden-sm-and-down" v-model="lang" :items="languagges" @change="changeLang"></v-select>
      <v-btn icon :ripple="false" class="ma-0 pa-0 hidden-md-and-up menu_icon" @click="drawer = !drawer" v-html="iconSvg(icon)"> </v-btn>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
    <v-dialog v-if="winnerDialog" v-model="winnerDialog" :retain-focus="false" persistent scrollable max-width="720px">
      <v-layout justify-center>
        <v-flex xs12>
          <v-toolbar class="pop-header" color="primary" dark>
            <v-toolbar-title> <v-icon left>mdi-gift</v-icon> Announcement on Instagram Event Winners </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card tile style="height: calc(100% - 64px); overflow-y: auto">
            <v-card-text>
              <v-sheet shaped color="grey lighten-4" class="pa-4 mb-4">
                <div class="ml-2 body-1">
                  Thank you to those who participated in the BeautyPlay World Instagram verification event. We will contact winners individually via Instagram
                  DM and send prizes to them.
                </div>
              </v-sheet>
              <v-toolbar color="cyan" dark flat>
                <v-tabs v-model="tabs" fixed-tabs>
                  <v-tabs-slider color="yellow"></v-tabs-slider>
                  <v-tab class=""> INSTAGRAM<br />EVENT </v-tab>
                  <v-tab class=""> EVENT1<br />ADVANCE RESERVATION </v-tab>
                  <v-tab class=""> EVENT2<br />EXPACTATIONS </v-tab>
                </v-tabs>
              </v-toolbar>
              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-card flat class="pa-1">
                    <div v-for="item in event3" :key="item.date">
                      <!--<h6 class="my-2"> {{ new Date(item.date).toLocaleDateString("ko-KR") }} </h6>-->
                      <v-sheet class="my-4">
                        <v-chip class="winner-label mt-1" label outlined v-for="instagramId in item.users" :key="instagramId">{{ instagramId }}</v-chip>
                      </v-sheet>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat class="pa-1">
                    <div v-for="item in event1" :key="item.date">
                      <!--<h6 class="my-2"> {{ new Date(item.date).toDateString() }} </h6>-->
                      <v-sheet class="my-4">
                        <div class="strong ml-2">Korean Winners (Coffee coupon)</div>
                        <v-chip class="winner-label mt-1" label outlined v-for="number in item.domestics" :key="number">{{ masking(number) }}</v-chip>
                      </v-sheet>
                      <v-sheet class="mb-4">
                        <div class="strong ml-2">Overseas Winner (Google Play Gift Card)</div>
                        <v-chip class="winner-label mt-1" label outlined v-for="number in item.overseas" :key="number">{{ masking(number) }}</v-chip>
                      </v-sheet>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat class="pa-1">
                    <div v-for="item in event2" :key="item.date">
                      <!-- <h6 class="my-2"> {{ new Date(item.date).toDateString() }} </h6> -->
                      <v-sheet class="my-4">
                        <div class="strong ml-2">Korean Winners (Coffee coupon)</div>
                        <v-chip class="winner-label mt-1" label outlined v-for="number in item.domestics" :key="number">{{ masking(number) }}</v-chip>
                      </v-sheet>
                      <v-sheet class="mb-4">
                        <div class="strong ml-2">Overseas Winner (Google Play Gift Card)</div>
                        <v-chip class="winner-label mt-1" label outlined v-for="number in item.overseas" :key="number">{{ masking(number) }}</v-chip>
                      </v-sheet>
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--     <v-btn color="primary darken-1" text @click="close">
                                                                                                    닫기
                                                                                                </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </div>
</template>

<script>
import eventData from '../../../assets/json/event.json';
import { getAuth, signOut } from 'firebase/auth';
import feather from 'feather-icons';
import socialTwo from '../../social/SocialTwo';
export default {
  components: {
    socialTwo,
  },
  data: () => ({
    drawer: false,
    lang: 'EN',
    languagges: ['KR', 'EN', 'JP'],
    items: [
      { title: 'Home', to: '#home' },
      { title: 'Event', to: '#event' },
      { title: 'Story', to: '#story' },
      { title: 'Roadmap', to: '#roadmap' },
      //{ title: "Shop", to: "#shop" },
    ],
    icon: 'menu',
    closeIcon: 'x',
    closeIcon: 'x',
    winnerDialog: false,
    tabs: 0,
  }),
  computed: {
    event1() {
      const array = eventData.event1;
      return array.sort();
    },
    event2() {
      const array = eventData.event2;
      return array.sort();
    },
    event3() {
      const array = eventData.event3;
      return array.sort();
    },
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    logOff() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          console.log('signed out');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeLang() {
      switch (this.lang) {
        case 'KR':
          this.$router.push('/');
          break;
        case 'EN':
          this.$router.push('/en');
          break;
        case 'JP':
          this.$router.push('/jp');
          break;
        default:
          this.$router.push('/');
          break;
      }
    },
    close() {
      this.winnerDialog = false;
      this.$nextTick(() => {});
    },
    masking(number) {
      const pn = number.substring(1);
      const last = pn.slice(-4);
      const first = pn.slice(0, 4);
      const size = pn.length - 8;
      const mask = '*'.repeat(size);
      return first + mask + last;
    },
  },
};
</script>

<style>
.soon ::before {
  content: 'SOON';
  position: absolute;
  font-size: 0.5rem;
  font-weight: 300;
  font-family: 'Noto Sans KR', sans-serif !important;
  color: #e77b9b;
  top: -0.2rem;
  left: 2rem;
}

.soon-mobile ::before {
  content: 'SOON';
  position: absolute;
  font-size: 0.5rem;
  font-weight: 300;
  font-family: 'Noto Sans KR', sans-serif !important;
  color: #e77b9b;
  top: 0rem;
  left: 1rem;
}

.scrollactive-item {
  font-family: 'Oleo Script', cursive;
  font-size: 2rem !important;
}

.v-navigation-drawer--is-mobile .v-list-item--link:last-child {
  border-bottom: 1px solid var(--color-border);
}
</style>
