<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="koz-story-content">
        <v-row>
          <v-col md="10" sm="12" cols="12" class="story-content-inner">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in storyContent" :key="item.id">
                <img :src="item.src" alt="story image" />
                <v-card flat v-for="(item, i) in item.content" :key="i">
                  <v-card-text>
                    <div class="author-info">
                      <h6>
                        <span>{{ item.name }}</span> - {{ item.designation }}
                      </h6>
                    </div>
                    <div class="inner">
                      <p v-html="item.description">
                        <!--{{ item.description }}-->
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
                <!-- End .v-tabs -->
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col md="2" sm="12" cols="12" class="">
            <div class="story-tabs">
              <v-tabs v-model="tab" centered hide-slider center-active>
                <v-tab v-for="item in tabItems" :key="item.id" :ripple="false">
                  <div class="thumb">
                    <img :src="item.src" alt="story image" />
                  </div>
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
        </v-row>
        <!-- End .v-tabs-items -->
      </div>
      <!-- End .koz-story-content -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      storyContent: [
        {
          id: 1,
          content: [
            {
              name: '#1 ',
              description: `Dear My Diary <br/><br/>
              안녕, 다이어리야 <br/> <br/>
              오늘은 나에게 모르는 소포가 도착했어. 소포를 풀어 보니 조그만 손거울이 있었고, 호기심에 가까이 얼굴을 비추어 보았는데 그만 거울 속으로 빨려 들어갔어. 잠깐 어지러웠지만 이내 도착한 ‘뷰티 플레이 월드’라는 신기한 가상 공간을 발견하게 되었어.<br/> <br/>
              참, 그곳에서 멋진 친구 JK도 만나고 ‘뷰티 플레이 아카데미’라는 학교도 가봤어. 거기서는 예뻐지는 K-beauty 비밀을 배울 수 있다고 해서 입학하기로 했는데, 나는 스스로 예쁘다고 생각한 적이 한 번도 없어서 잘할 수 있을지 걱정이 되었어.<br/> <br/>
            나 잘할 수 있겠지? 앞으로 나만의 아름다움을 찾은 모든 여정을 잘 헤쳐 갈 수 있도록 응원해 줘. 그리고 뷰티 플레이 월드에서 변신한 나의 아바타 모습도 기대해 줘.`,
              designation: ' 거울속 ‘뷰티플레이월드’로 여행 – 여기가＇뷰티플레이 월드’라고? ',
            },
          ],
          src: require('../../assets/images/story/story2-1.png'),
        },
        {
          id: 2,
          content: [
            {
              name: '#2 ',
              description: `Dear My Diary <br/><br/>
                            안녕, 다이어리야<br/><br/>
                드디어 ‘뷰티플레이 아카데미’를 졸업하는 날이야. 그동안 퍼스널컬러, 스킨케어,메이크업까지 정말 많은 K-Beauty 비밀을 배웠어. 과거에 나는 스스로가 예쁘지 않다고 생각했지만 이제는 달라. ‘뷰티플레이 아카데미’에서 누구나 ‘K-beauty’로 자신만의 아름다운 모습을 찾을 수 있다는 것을 배웠어. <br/><br/>
                그래서 이제는 새로운 꿈을 꾸게 되었어. 바로 ‘K- Beauty 인플루언서’가 되는 거야. 나처럼 과거에 예쁘지 않다고 생각한 글로벌 친구들에게 ‘누구나 자신의 진정한 아름다움을 찾을 수 있다＇고 이야기 하고 싶어.<br/><br/>
                앞으로 ‘K- Beauty 인플루언서’로 모두를 아름답게 만드는 유진(YUJIN)이가 될거야.`,
              designation: ' 뷰티 플레이 아카데미’ 졸업과 새로운 꿈 – 뷰티 인플루언서가 될 거야!',
            },
          ],
          src: require('../../assets/images/story/story2-2.png'),
        },
        {
          id: 3,
          content: [
            {
              name: '#3 ',
              description: `Dear My Diary <br/><br/>
                            안녕, 다이어리야<br/><br/>
                ‘뷰티플레이 아카데미’를 졸업한지 6개월이 지났어. 그동안 K-Beauty 인플루언서로 전세계에서 다양한 친구들을 만나고 함께 성장할 수 있어서 너무 행복했어. <br/>
                내가 참여했던 프로젝트와 뷰티플레이 컨텐츠가 궁금한 친구들은 아래 계정으로 놀러와~!! <br/>
                인스타그램 : @Beautyplay.global<br/>
                &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;@Beautyplay.official<br/><br/>
                유튜브 : <a href = "https://www.youtube.com/@beautyplayofficial">www.youtube.com/@beautyplayofficial</a> <br/><br/>
                유진(YUJIN) : <a href = "https://web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en">web.zepeto.me/share/user/profile/zepeto.kcii?referrer=&language=en</a>
                `,
              designation: ` 뷰티인플루언서 ‘YUJIN’ – 전 세계에 'K-Beauty Spirit＇을 알려요`,
            },
          ],
          src: require('../../assets/images/story/story2-3.png'),
        },
        {
          id: 4,
          content: [
            {
              name: '#4 ',
              description: `Dear My Diary <br/><br/>
                            안녕, 다이어리야<br/><br/>
                K-Beauty 노하우로 마법과 같은 화장품을 만들고 싶어 졌어. 바르고 나면 변신이 가능한 립스틱, 가면처럼 쓸 수 있는 마스크팩 처럼 기발한 화장품을 만들 수 있지 않을까? 그래서 결심 했어. 마법과 같은 K-Beauty 화장품 회사를 세우기로!<br/><br/>
                내 회사이름은 ‘뷰플코스메틱(Beauple Cosmetic)이야. ‘뷰티플레이 월드’에 회사건물이 있으니까 꼭 놀러와. <br/>
                ‘뷰플코스메틱(Beauple Cosmetic) 건물에는 그동안 내가 인플루언서로 참여한 프로젝트도 소개하고 가장 좋아하는 화장품도 알리고 있어. 꼭 방문하고 방문 소감도 남겨줘.`,
              designation: ' ‘유진(YUJIN)’이의 ‘뷰플코스메틱(Beauple Cosmeitc)’ - 마법과 같은 화장품을 만들어보자!',
            },
          ],
          src: require('../../assets/images/story/story2-4.png'),
        },
        {
          id: 5,
          content: [
            {
              name: '#5 ',
              description: `Dear My Diary <br/><br/>
                안녕, 다이어리야<br/><br/>
                너무 좋은 소식이 있어. 뷰티플레이월드(Beautyplayworld)에 ‘미스터리 편집숍(Mystery Cosmetic Shop)’이 생겼어. 여기서는 전세계에 있는 신기한 마법 화장품을 한자리에서 구경할 수 있어. K-Beauty 인플루언서인 내가 전세계 마법사들이 보내온 신기한 화장품을 직접 써보고 선정해서 편집숍에서 소개하고 있어.<br/><br/>
                그리고 ‘K-Beauy 메이커 콘테스트’로 앞으로도 더욱 재미있고 신기한 화장품을 소개할거야. 푸짐한 경품과 선물도 주고 신기한 화장품도 함께 만들어가는 ‘K-Beauy 메이커 콘테스트’를 기억해줘!`,
              designation: ` 미스터리 편집숍(Mystery Cosmetic Shop)에 놀러오세요.`,
            },
          ],
          src: require('../../assets/images/story/story2-5.png'),
        },
        /*
                {
                    id: 6,
                    content: [{
                        name: "#6 ",
                        description: `\“여기는‘뷰티플레이 월드’야, 너만의 진짜 아름다움을 찾을 수 있는 모든 것이 준비되어 있어!\”<br />JK가 자신있게 대답했다.<br />\“이곳에서 빛나는 너를 찾을 수 있도록 내가 함께 해도 될까?\”<br />JK의 제안에 유진은 고개를 끄덕였다.<br />\“정말 저도 빛나는 사람이 될 수 있을까요?\”<br />여전히 믿어지지 않는다는 듯, 질문을 쏟아내는 유진. JK가 활짝 웃으며 말했다.<br />\“그럼 수락한 것으로 알고 안내할께. 자, 가자!\”`,
                        designation: " True Beauty Project가 시작되다!",
                    }],
                    src: require("../../assets/images/story/story-6.jpg")
                }*/
      ],

      tabItems: [
        {
          id: 1,
          src: require('../../assets/images/story/story2-thum-1.png'),
        },
        {
          id: 2,
          src: require('../../assets/images/story/story2-thum-2.png'),
        },
        {
          id: 3,
          src: require('../../assets/images/story/story2-thum-3.png'),
        },
        {
          id: 4,
          src: require('../../assets/images/story/story2-thum-4.png'),
        },
        {
          id: 5,
          src: require('../../assets/images/story/story2-thum-5.png'),
        },
        /*
                {
                    id: 6,
                    src: require("../../assets/images/story/story-thum-6.jpg"),
                }*/
      ],
    };
  },
};
</script>
