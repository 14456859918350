import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './plugins/firebase';
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import Loading from 'vue-loading-overlay';
import AOS from 'aos';

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/scss/main.scss';
import "aos/dist/aos.css";

Vue.config.productionTip = false;

Vue.use(CoolLightBox);
Vue.use(VueScrollactive);
Vue.use(Loading);

//Vue.prototype.$gsap = gsap;
new Vue({
    created() {
        AOS.init();
    },
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')