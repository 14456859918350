import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Main from '../views/Main.vue'
import MainENG from '../views/MainENG.vue'
import MainJPN from '../views/MainJPN.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Main,
        meta: {
            title: ':: BEAUTY PLAY WOLRD | 뷰티플레이 월드 ::'
        }
    },
    {
        path: '/en',
        component: MainENG,
        meta: {
            title: ':: BEAUTY PLAY WOLRD ::'
        }
    },
    {
        path: '/jp',
        component: MainJPN,
        meta: {
            title: ':: ビューティープレイルワールド | BEAUTY PLAY WOLRD ::'
        }
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 - 페이지를 찾을 수 없습니다.'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router