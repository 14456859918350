<template>
  <section>
    <div class="section-title text-center" data-aos="fade-down">
      <h2 class="heading-title mb-6">뷰티플레이 제페토 월드맵 방명록</h2>
    </div>
    <v-row class="row--35 align-center">
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-right">
        <div class="section-title">
          <p class="event-desc">
            뷰티플레이월드 시즌2, <br />
            유진이에게 하고 싶은 말을 방명록에 적어주세요
          </p>
        </div>
        <div class="thumbnail mt-6">
          <img class="w-100" src="../../assets/images/event/event09.png" alt="Beautyplay World Image" />
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-left">
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <div class="about-inner inner thumbnail">
          <v-spacer class="mt-12 hidden-sm-and-down"></v-spacer>
          <v-data-table
            id="comment-table"
            :headers="headers"
            :items="commentList"
            hide-default-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            mobile-breakpoint
            hide-default-footer
            class="elevation-0"
          >
          </v-data-table>
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-col>
      <v-snackbar centered multi-line v-model="snack" :color="snackColor" elevation="10">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="rgba(255,255,255,0.6)" text v-bind="attrs" @click="snack = false"> 닫기 </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </section>
</template>

<script>
import telcode from '../../assets/json/telcode_kr.json';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { getDatabase, set, ref, update, onValue, child, get } from 'firebase/database';
import dedupeModelListeners from 'vuetify/lib/util/dedupeModelListeners';
export default {
  components: {},
  data() {
    return {
      valid: false,
      phoneValid: false,
      dialog: false,
      signDialog: false,
      otpValid: false,
      appVerifier: '',
      otp: '',
      tel: '',
      selectedCountry: '대한민국',
      resKey: '',
      reqSuccess: false,
      telRules: [
        (v) => !!v || '전화번호는 필수항목입니다.',
        (v) => /^[0-9]+$/.test(v) || '전화번호는 숫자입니다.',
        (v) => v.length <= 11 || '전화번호는 최대 11자 입니다.',
        (v) => v.length > 7 || '전화번호가 너무 짧습니다.',
      ],
      otpRules: [
        (v) => !!v || '인증번호를 입력해주세요.',
        (v) => /^[0-9]+$/.test(v) || '인증번호는 숫자입니다.',
        (v) => v.length === 6 || '인증번호는 6자 입니다.',
      ],
      snackColor: '',
      snackText: '',
      snack: false,
      comment: '',
      commentList: [],
      notice: `<p>- 제페토 월드 맵이 오픈한 후, 등록하신 핸드폰 번호로 월드 맵 방문 URL이 SMS로 발송됩니다.</p><p>- 등록한 핸드폰 번호가 정상적이지 않을 경우 SMS가 수신되지 않을 수 있습니다.</p><p>- 이벤트 당첨자 발표는 이벤트 종료 후 당첨자에게 개별 SMS로 발송되어 지급 됩니다.</p><p>- 휴대폰 문자 스팸 기능이 설정되어 있을 경우 정상적으로 문자를 받을 수 없습니다.</p><p>- 타인의 개인정보 도용 시 이벤트 당첨 취소 및 형사처분의 대상이 될 수 있습니다.</p>`,
      commentRules: [(v) => !!v || '내용을 작성해 주세요.'],
      headers: [
        { text: 'No', value: 'number' },
        { text: '기대평', value: 'body' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
      page: 1,
      itemsPerPage: 12,
      totalComments: '',
    };
  },
  created() {
    this.getComments();
    // this.getResultJSON();
  },
  computed: {
    addDisable() {
      return this.valid ? false : true;
    },
    countryList() {
      const list = telcode.list;
      const array = list.map((i) => i.country);
      return array.sort();
    },
    applyDisable() {
      return this.phoneValid ? false : true;
    },
    totalRecords() {
      return this.commentList.length;
    },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage);
    },
  },
  methods: {
    openModal() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        this.dialog = true;
      } else {
        //this.snackShow('goSign')
        this.reqSuccess = false;
        this.signDialog = true;
      }
    },
    snackShow(action, param) {
      this.snack = true;
      switch (action) {
        case 'complete':
          this.snackColor = 'success';
          this.snackText = '기대평이 등록되었습니다.';
          break;
        case 'success':
          let user = param.slice(-4);
          this.snackColor = 'success';
          this.snackText = user + '님 사전예약이 확인되었습니다.';
          this.$nextTick(() => {
            this.closeSign();
            this.openModal();
          });
          break;
        default:
          break;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.comment = '';
      });
    },
    closeSign() {
      this.signDialog = false;
      this.$nextTick(() => {
        this.selectedCountry = '대한민국';
        this.tel = '';
      });
    },
    getRegionCode() {
      const list = telcode.list;
      const obj = list.find((i) => i.country === this.selectedCountry);
      return obj.code;
    },
    authByPhone() {
      let loader = this.$loading.show();
      const phoneNumber = this.getRegionCode() + this.tel.toString();
      const appVerifier = window.recaptchaVerifier;
      const auth = getAuth();
      auth.useDeviceLanguage();
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          //console.log(confirmationResult)
          this.reqSuccess = true;
          loader.hide();
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-phone-number':
              alert('잘못된 전화번호입니다.');
              break;
            default:
              alert(error.code);
              break;
          }
          console.log(error.code);
          grecaptcha.reset(window.recaptchaWidgetId);
          this.reqSuccess = false;
          loader.hide();
        });
    },
    initReCaptcha() {
      const auth = getAuth();
      auth.useDeviceLanguage();
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container2',
        {
          size: 'invisible',
          callback: (response) => {
            this.onSignInSubmit();
          },
          'expired-callback': () => {
            console.log('reCaptcha is expired!');
          },
        },
        auth
      );
    },
    onSignInSubmit() {
      console.log('recaptcha', 'verified!');
    },
    verifyOTP() {
      let loader = this.$loading.show();
      const code = this.resKey;
      confirmationResult
        .confirm(code)
        .then((result) => {
          const user = result.user;
          //console.log(user)
          //this.writeUserData(user.uid, user.phoneNumber);
          loader.hide();
          this.snackShow('success', user.phoneNumber);
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-verification-code':
              alert('잘못된 인증번호입니다.');
              break;
            default:
              alert(error.code);
              break;
          }
          console.log(error);
          loader.hide();
        });
    },
    /* writeUserData(userId, phoneNumber) {
            const db = getDatabase();
            let today = new Date();
            set(ref(db, 'users/' + userId), {
                phoneNumber: phoneNumber,
                signDate: today,
                lang: 'KR'
            });
        }, */
    checkPhone() {
      const tel = this.tel.toString();
      const localNumber = tel.charAt(0) === '0' ? tel.substr(1) : tel;
      const phoneNumber = this.getRegionCode() + localNumber;
      const db = getDatabase();
      const usersRef = ref(db, 'users/');
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const userList = Object.values(snapshot.val());
          const exist = userList.find((i) => i.phoneNumber === phoneNumber);
          if (!exist) {
            alert('사전예약이 되어 있지 않습니다. :( \n 먼저 사전예약을 신청해주세요.');
          } else {
            this.initReCaptcha();
            this.authByPhone();
          }
        } else {
          console.log('No data available');
        }
      });
    },
    getResultJSON() {
      const db = getDatabase();
      const commentsRef = ref(db, 'comments/');
      const usersRef = ref(db, 'users/');
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const result = Object.values(snapshot.val());
          console.log(JSON.stringify(result));
          //console.log(Object.values(snapshot.val()))
        } else {
          console.log('No data available');
        }
      });
    },
    getComments() {
      const db = getDatabase();
      const commentsRef = ref(db, 'comments/');
      onValue(commentsRef, (snapshot) => {
        if (snapshot.exists()) {
          this.totalComments = snapshot.val().length;
          const filteredList = snapshot.val().filter((i) => i.show === true);
          this.commentList = [...filteredList].reverse();
          //    console.log(snapshot.val());
        } else {
          console.log('No data available');
        }
      });
    },
    isOwn(uid) {
      const auth = getAuth();
      const user = auth.currentUser;
      return user && user.uid === uid ? true : false;
    },
    hideItem(item) {
      const db = getDatabase();
      const today = this.DateTimeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      set(ref(db, 'comments/' + item.id), {
        body: item.body,
        id: item.id,
        number: item.number,
        show: false,
        uid: item.uid,
        createdAt: item.createdAt,
        deletedAt: today,
        lang: 'KR',
      });
    },
    addComment() {
      //console.log(this.totalComments)
      const db = getDatabase();
      const auth = getAuth();
      const user = auth.currentUser;
      const today = this.DateTimeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      set(ref(db, 'comments/' + this.totalComments), {
        body: this.comment,
        id: this.totalComments,
        number: user.phoneNumber.slice(-4),
        show: true,
        uid: user.uid,
        createdAt: today,
        lang: 'KR',
      }).then(() => {
        this.snackShow('complete', '');
        this.$nextTick(() => {
          this.close();
        });
      });
    },
    DateTimeFormat(date, pattern) {
      let dateString = pattern.replace(/(yyyy|MM|dd|HH|mm|ss|SSS)/g, function (match) {
        let matchString = '';
        switch (match) {
          case 'yyyy':
            matchString = date.getFullYear();
            break;
          case 'MM':
            matchString = date.getMonth() + 1;
            break;
          case 'dd':
            matchString = date.getDate();
            break;
          case 'HH':
            matchString = date.getHours();
            break;
          case 'mm':
            matchString = date.getMinutes();
            break;
          case 'ss':
            matchString = date.getSeconds();
            break;
          case 'SSS':
            matchString = date.getMilliseconds();
            break;
          default:
            matchString = match;
            break;
        }
        if (match == 'SSS') {
          if (matchString < 10) {
            matchString = '00' + matchString;
          } else if (matchString < 100) {
            matchString = '0' + matchString;
          }
        } else {
          if (typeof matchString == 'number' && matchString < 10) {
            matchString = '0' + matchString;
          }
        }
        return matchString;
      });
      return dateString;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 767px) {
  .about-button .btn-default {
    font-size: 1.2rem;
  }
}
</style>
