<template>
  <section>
    <div class="section-title text-center" data-aos="fade-down">
      <h2 class="heading-title mb-6">
        <span class="title-accent">#BEAUTYPLAYWORLD</span><br />
        K-뷰티메이커 <br />
        미스터리 뷰티숍에 들어갈 신기하고 재미난 화장품 만들기
      </h2>
    </div>
    <v-row class="row--35 align-center event-wrap">
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-right">
        <div class="section-title">
          <p class="event-desc">
            뷰티플레이 제페토 월드 시즌2 오픈! <br class="always" />
            유진 월드에 삽입할 수 있는 신기한 화장품 모델링을 제출하여 선정된 분들에게는 50만원 상당의 올리브영 상품권을 드립니다!<br class="always" />
          </p>
          <p class="strong event-content">
            당첨인원 : 5명 선정 <br class="always" />
            참여기간 : 2023. 10. 20 ~ 2023. 11. 20 <br class="always" />
            당첨발표 : 2023. 11. 27 <br class="always" />
            발표장소 : 뷰티플레이클럽 홈페이지
            <a class="content-link" href="https://smartstore.naver.com/obt_obed/products/4481531417" target="_blank">(https://www.beautyplay.club)</a>
            <br class="always" />
            참여방법
          </p>
        </div>
        <v-sheet class="event-rules mt-1 pa-4 elevation-0" rounded>
          <div class="text-left body-1">
            1) 월드와 어울리는 화장품, 조형물, 코디 아이템 등 구상<br class="always" />
            2) 제페토 스튜디오 등록 가능한 코디 아이템 혹은 월드에 삽입 가능한 신기하고 재미난 화장품 3D파일로 제작 <br />
            (예-걸어다니는 립스틱, 눈커지는 아이섀도우 등) <br class="always" />
            3) 제페토 아이디와 제품명, 간단한 기획의도를 작성하여 접수메일을 통해 파일을 제출하고 뷰티플레이월드 방문 인증샷을 필수 태그와 함께 업로드하면 참여
            완료!<br class="always" />
            <p class="body-1 mt-2 mb-4 primary--text">
              필수해시태그 : <span class="strong">#뷰티플레이월드 #K-뷰티메이커 #미스터리뷰티숍</span><br class="always" />
              월드태그 : <span class="strong">뷰티플레이 월드</span>&emsp;캐릭터태그 : <span class="strong">YUJIN</span>
            </p>
            <v-alert class="body-2 mb-0" dense color="grey lighten-4" border="left">
              · 공모작에 대한 저작권 문제는 제출자 본인에게 있습니다.<br class="always" />
              · 공모작은 반환되지 않으며 공모작에 대한 저작권 등 일체의 권리는 대한화장품산업연구원에 있습니다.<br class="always" />
              · 공모작은 일부 수정 또는 변경해 사용될 수 있습니다.<br class="always" />
              · 이미지를 도용하여 참여한 경우 심사 대상에서 제외됩니다.<br class="always" />
              · 심사 기준은 기획의도, 완성도, 연관성 3가지 항목으로 진행되며 추후 개인별 심사점수는 별도 제공되지 않습니다.<br class="always" />
              · 접수메일 : info@kcii.re.kr
            </v-alert>
          </div>
        </v-sheet>
        <v-sheet class="gift-box my-4 pa-4 elevation-2" rounded>
          <div class="d-sm-flex text-center text-sm-left">
            <img class="w-120" src="../../assets/images/event/gift4.png" />
            <div class="pl-4 desc-wrap">
              <h6>증정경품</h6>
              <p class="body-2 gift-desc">
                1등 올리브영 상품권 30만원 - 1명<br />
                2등 올리브영 상품권 5만원 - 4명
              </p>
            </div>
          </div>
        </v-sheet>
        <!--
                <div class="about-button mt--30">
                    <a class="btn-default text-center v-btn--block" href="" target="_blank">뷰티플레이 월드 바로가기</a>
                </div>
                -->
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-left">
        <div class="thumbnail">
          <img class="w-100" src="../../assets/images/event/event08.png" alt="Event Image" />
        </div>
      </v-col>
      <v-snackbar centered multi-line v-model="snack" :color="snackColor" elevation="10">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="rgba(255,255,255,0.6)" text v-bind="attrs" @click="snack = false"> 닫기 </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackColor: '',
      snackText: '',
      snack: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style>
.about-button .btn-default {
  z-index: 1000;
}
.policy {
  overflow: scroll;
}

.policy p,
.notice p {
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
  margin-bottom: 0.125rem !important;
}

.policy h5 {
  font-size: 0.9rem !important;
  margin: 8px auto !important;
}

.apply-btn-wrap {
  height: 86px;
}

.btn-apply {
  height: 56px !important;
}
.pop-header .v-toolbar__content {
  flex-wrap: inherit;
}
.pop-header.v-toolbar:not(.tab-toolbar) .v-btn.v-btn.v-size--default:not(.btn-default) {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .section-title span.subtitle {
    line-height: 1.5rem !important;
  }
}
</style>
