<template>
  <section>
    <div class="section-title text-center" data-aos="fade-down">
      <h2 class="heading-title mb-6">
        <span class="title-accent">#BEAUTYPLAYWORLD</span><br />
        K-ビューティーメーカー <br />
        ミステリービューティーショップの面白い化粧品作り
      </h2>
    </div>
    <v-row class="row--35 align-center event-wrap">
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-right">
        <div class="section-title">
          <p class="event-desc">
            ビューティープレイゼペットワールド・シーズン2公開!<br class="always" />
            ユジンのワールドに作り込める面白い化粧品モデリングを提出してください。<br class="always" />
            ご当選された方には総額50万ウォン相当のオリープヤングの商品券を贈呈いたします。
          </p>
          <p class="strong event-content">
            当選人数: 5名 <br class="always" />
            参加期間: 2023年10月20日~2023年11月20日 <br class="always" />
            当選発表: 2023年11月27日 <br class="always" />
            発表場所: ビューティープレイクラブホームペー
            <a class="content-link" href="https://smartstore.naver.com/obt_obed/products/4481531417" target="_blank">(https://www.beautyplay.club)</a>
            <br class="always" />
            参加方法
          </p>
        </div>
        <v-sheet class="event-rules mt-1 pa-4 elevation-0" rounded>
          <div class="text-left body-1">
            1) ワールドにぴったりな化粧品、造形物、コーディアイテム等企
            <br class="always" />
            2) ゼペットスタジオに登録できるコーディアイテムやワールドに作り込める面白い化粧品を3Dファイルで制作 <br />
            （例：歩く口紅、目が大きくなるアイシャドウなど）
            <br class="always" />
            3)
            ゼペットIDとともに簡単な企画意図（出品名）を作成し、そのファイルをメールで提出した後、ビューティープレイワールドの訪問認証ショットを必須タグとともにアップロードすると、参加申し込み完了！
            <br class="always" />
            <p class="body-1 mt-2 mb-4 primary--text">
              必修ハッシュタグ：<span class="strong"
                >＃ビューティープレイワールド　<br />
                ＃K-ビューティーメーカー <br />
                ＃ミステリービューティーショップ</span
              ><br class="always" />
              ワールドタグ：<span class="strong">ビューティープレイワールド</span><br class="always" />
              キャラクタータグ：<span class="strong">YUJIN</span>
            </p>
            <v-alert class="body-2 mb-0" dense color="grey lighten-4" border="left">
              · 応募作品の著作権上の問題は、応募者にて対処願います。<br class="always" />
              · 応募作品は返却いたしません。なお、応募作品の著作権等すべての権利は大韓化粧品産業研究院に帰属します。<br class="always" />
              · 応募作品は一部修正または変更して利用される場合があります。<br class="always" />
              · 画像を盗用して応募した場合、審査の対象外となります。<br class="always" />
              · 企画意図、完成度、関連性の3つの項目を基準に審査され、個人の点数は通知しません。<br class="always" />
              · 受付メール : info@kcii.re.kr
            </v-alert>
          </div>
        </v-sheet>
        <v-sheet class="gift-box my-4 pa-4 elevation-2" rounded>
          <div class="d-sm-flex text-center text-sm-left">
            <img class="w-120" src="../../../assets/images/event/gift4.png" />
            <div class="pl-4 desc-wrap">
              <h6>賞品案内</h6>
              <p class="body-2 gift-desc">
                1位 : オリーブヤング商品券30万ウォン(1名)<br />
                2位 : オリーブヤング商品券5万ウォン (4名)
              </p>
            </div>
          </div>
        </v-sheet>
        <!--
                <div class="about-button mt--30">
                    <a class="btn-default text-center v-btn--block" href="" target="_blank">뷰티플레이 월드 바로가기</a>
                </div>
                -->
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12" data-aos="fade-left">
        <div class="thumbnail">
          <img class="w-100" src="../../../assets/images/event/event08.png" alt="Event Image" />
        </div>
      </v-col>
      <v-snackbar centered multi-line v-model="snack" :color="snackColor" elevation="10">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="rgba(255,255,255,0.6)" text v-bind="attrs" @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackColor: '',
      snackText: '',
      snack: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style>
.about-button .btn-default {
  z-index: 1000;
}
.policy {
  overflow: scroll;
}

.policy p,
.notice p {
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
  margin-bottom: 0.125rem !important;
}

.policy h5 {
  font-size: 0.9rem !important;
  margin: 8px auto !important;
}

.apply-btn-wrap {
  height: 86px;
}

.btn-apply {
  height: 56px !important;
}
.pop-header .v-toolbar__content {
  flex-wrap: inherit;
}
.pop-header.v-toolbar:not(.tab-toolbar) .v-btn.v-btn.v-size--default:not(.btn-default) {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .section-title span.subtitle {
    line-height: 1.5rem !important;
  }
}
</style>
